import { EcCustomProps } from './types'
import { registerApplication as registerSingleSpaApplication } from 'single-spa'
import { importSpa } from '../warehouse'

import RouteRecognizer from 'route-recognizer'

const excludeRouter = new RouteRecognizer()

const excludedUrls = [
  '/companyCode',
  '/companyCode/*',
  '/sign-in',
  '/sign-in/*',
  '/payroll/Details/EmployeeComparisonReport/:id',
  '/login/:companyCode/:registrationId/register'
]

excludedUrls.forEach((route) => {
  excludeRouter.add([
    { path: route.toLowerCase(), handler: 'ec-navigation-spa' }
  ])
})

const matchesRoute = (route: string) => {
  const matches = excludeRouter.recognize(route.toLowerCase())
  return matches && matches.length > 0
}

const registerNavigationSpa = (customProps: EcCustomProps) => {
  registerSingleSpaApplication({
    name: 'ec-navigation-spa',
    activeWhen: (location) => !matchesRoute(location.pathname),
    app: () => importSpa('ec-navigation-spa'),
    customProps
  })
}

export default registerNavigationSpa
