import { EcCustomProps } from './types'
import { registerApplication as registerSingleSpaApplication } from 'single-spa'
import { importSpa } from '../warehouse'

import RouteRecognizer from 'route-recognizer'

const excludeRouter = new RouteRecognizer()

// show chatbot on all pages after the user is logged in
const excludedUrls = [
  '/companyCode',
  '/companyCode/*',
  '/sign-in',
  '/sign-in/*',
  '*/Dashboard/Root/Messages',
  '/login/:companyCode/:registrationId/register'
]

excludedUrls.forEach((route) => {
  excludeRouter.add([
    { path: route.toLowerCase(), handler: 'payroll-chatbot-spa' }
  ])
})

const matchesRoute = (route: string) => {
  const matches = excludeRouter.recognize(route.toLowerCase())
  return matches && matches.length > 0
}

const registerPayrollChatbotSpa = (customProps: EcCustomProps) => {
  registerSingleSpaApplication({
    name: 'payroll-chatbot-spa',
    activeWhen: (location) => !matchesRoute(location.pathname),
    app: () => importSpa('payroll-chatbot-spa'),
    customProps
  })
}

export default registerPayrollChatbotSpa
