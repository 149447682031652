import { EcCustomProps } from './types'
import { registerApplication as registerSingleSpaApplication } from 'single-spa'
import { importSpa } from '../warehouse'

import RouteRecognizer from 'route-recognizer'

const recognizer = new RouteRecognizer()

// The tax center spa requires also rendering an alert on every page, therefore anything outside the login could be a potential location to show it.
const excludedUrls = [
  '/companyCode',
  '/companyCode/*',
  '/sign-in',
  '/sign-in/*',
  '/login/:companyCode/:registrationId/register'
]

excludedUrls.forEach((route) => {
  recognizer.add([
    {
      path: route.toLowerCase(),
      handler: 'payroll-tax-center-spa'
    }
  ])
})

const excludeRoute = (route: string) => {
  const matches = recognizer.recognize(route.toLowerCase())
  return matches && matches.length > 0
}

const registerPayrollTaxCenterSpa = (customProps: EcCustomProps) => {
  registerSingleSpaApplication({
    name: 'payroll-tax-center-spa',
    activeWhen: (location) => !excludeRoute(location.pathname),
    app: () => importSpa('payroll-tax-center-spa'),
    customProps
  })
}

export default registerPayrollTaxCenterSpa
